import {IPeakDevice} from '../../lib/ble2/v2/PeakDevice/IPeakDevice';
import {Device} from '../types';

export const getDeviceFromPeak = (peak: IPeakDevice): Device => ({
  id: peak.peripheralId,
  name: peak.name,
  product: {
    name: peak.product.name,
    type: peak.product.type,
    features: peak.product.features,
    consistent: peak.product.consistent,
  },
  selectedHeatCycle: peak.attributes?.selectedHeatCycle ?? 0,
  ...(peak.euid != undefined && {euid: peak.euid}),
  ...(peak.gitHash != undefined && {gitHashString: peak.gitHash}),
  ...(peak.softwareRevision != undefined && {
    softwareRevisionString: peak.softwareRevision,
  }),
  ...(peak.modelNumber != undefined && {modelNumberString: peak.modelNumber}),
  ...(peak.serialNumber != undefined && {
    serialNumberString: peak.serialNumber,
  }),
  ...(peak.broadcast != undefined && {
    broadcastingCounter: peak.broadcast.counter,
    broadcastingKey: peak.broadcast.key,
  }),
  ...(peak.attributes?.dateOfBirth != undefined && {
    dob: peak.attributes?.dateOfBirth * 1000,
  }),
  ...(peak.attributes?.operatingState != undefined && {
    state: peak.attributes?.operatingState,
  }),
  ...(peak.attributes?.batteryLevel != undefined && {
    battery: peak.attributes?.batteryLevel,
  }),
  ...(peak.attributes?.totalHeatCycles != undefined && {
    hits: peak.attributes?.totalHeatCycles,
  }),
  ...(peak.attributes?.dabTotalTime != undefined && {
    totalTime: peak.attributes?.dabTotalTime,
  }),
  ...(peak.attributes?.batteryCapacity != undefined && {
    batteryCapacity: peak.attributes?.batteryCapacity,
  }),
  ...(peak.attributes?.chargeState != undefined && {
    batteryChargeState: peak.attributes?.chargeState,
  }),
  ...(peak.attributes?.chargeEstimatedTimeToFull != undefined && {
    batteryChargeEstTimeToFull: peak.attributes?.chargeEstimatedTimeToFull,
  }),
  ...(peak.attributes?.dabsPerDay != undefined && {
    dailyDabs: peak.attributes?.dabsPerDay ?? 0,
  }),
  ...(peak.attributes?.approxDabsRemaining != undefined && {
    approxDabsRemainingCount: peak.attributes?.approxDabsRemaining,
  }),
  ...(peak.attributes?.utcTime != undefined && {
    utcTime: peak.attributes?.utcTime,
  }),
  ...(peak.attributes?.chamberType != undefined && {
    chamberType: peak.attributes?.chamberType,
  }),
  settings: {
    ...(peak.attributes?.stealth != undefined && {
      stealth: peak.attributes?.stealth,
    }),
    ...(peak.attributes?.currentTemperature != undefined && {
      currentTemp: peak.attributes?.currentTemperature,
    }),
    ...(peak.attributes?.targetTemperature != undefined && {
      targetTemp: peak.attributes?.targetTemperature,
    }),
    lanternMode: Boolean(peak.attributes?.lanternTime ?? 0 > 0),
  },
});
